import React, { useContext, useState, useEffect, useRef } from 'react';
import { isIE } from 'react-device-detect';
import { useStaticQuery, graphql } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import UAParser from 'ua-parser-js';
import { Footer, Header, Sentry, BadBrowser } from '.';
import SEO from './SEO';
import 'typeface-inter';
import { userData } from '../utils/userDataToAmplitude';
import { GeoContext, AuthContext, TrackEventContext } from '../context';
import { AUSTRALIA } from '../utils/countryNames';
import { transformSnakeKeysToCamel, trackAmplitudeEvent } from '../utils';
import '../sass/global/styles.scss';
import './Layout.scss';

const parser = new UAParser();

// Helper to destructure SEO fields from Prismic page and return this in the format expected by the SEO component
// export const setupSeoData = staticData => {
const setupSeoData = pageData => {
  const camelPageData = transformSnakeKeysToCamel(pageData);
  const {
    defaultMetaTitle,
    metaTitle,
    metaDescription,
    openGraphImage,
    schema,
    firstPublicationDate,
    lastPublicationDate,
  } = camelPageData;

  const author = camelPageData?.author?.text;
  const featuredImage = camelPageData?.featuredImage?.url;

  // Enable template to automatically generate a meta title by passing it through as the autoTitle argument
  const title = metaTitle?.text || defaultMetaTitle || 'Equitise';

  const { text: desc } = metaDescription || {};

  return {
    title,
    desc,
    banner: openGraphImage?.url,
    schema: schema?.text,
    firstPublicationDate,
    lastPublicationDate,
    author,
    featuredImage,
  };
};

export const Layout = props => {
  const staticData = useStaticQuery(settingsQuery);
  const mergedPrismicPreviewData = useMergePrismicPreviewData(staticData);
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};

  const usePrevious = value => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  };

  const [currentUrl, setCurrentUrl] = useState('');
  const [currentFullUrl, setCurrentFullUrl] = useState('');
  const [currentEmailFromUrl, setCurrentEmailFromUrl] = useState('');
  const emailFromUrlSearchParam = String(currentEmailFromUrl.search).includes('?email=')
    ? String(currentEmailFromUrl.search)
      .split('?email=')
      .filter(el => el !== '')[0]
    : undefined;

  const prevUrl = usePrevious(currentUrl);

  useEffect(() => {
    setCurrentUrl(window.location.pathname);
    setCurrentFullUrl(window?.location?.href);
    setCurrentEmailFromUrl(window?.location);
  }, []);

  if (
    (currentFullUrl.includes('localhost:8000') ||
      currentFullUrl.includes('equitise.com') ||
      currentFullUrl.includes('v3-stg.equitise.com')) &&
    currentUrl &&
    currentUrl !== prevUrl
  ) {
    const ua = navigator?.userAgent;
    const result = parser.setUA(ua).getResult();

    const browserName = result?.browser?.name;
    const browserVersion = result?.browser?.version;
    const deviceBrand = result?.device?.vendor;
    const deviceModel = result?.device?.model;
    const deviceType = result?.device?.type;
    const operatingSystem = result?.os?.name;
    const operatingSystemVersion = result?.os?.version;

    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail || emailFromUrlSearchParam,
        eventName: `Page View - ${currentUrl}`,
        eventData: {
          pageUrl: currentUrl,
          previousUrl: window.previousPath,
          userAgent: ua,
          browserName,
          browserVersion,
          deviceBrand,
          deviceModel,
          deviceType,
          operatingSystem,
          operatingSystemVersion,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event Page View failed');
    }
  }

  const { settings, prismicTeam, prismicBlogLandingPage } = mergedPrismicPreviewData.data || {};

  const { countryName: countryOfResidence } = useContext(GeoContext) || {};

  const {
    children,
    headerProps = {},
    pageData,
    bodyClassName,
    location,
    hideCommonUi,
    article,
    inheritedClassnames,
    isNewsletterCta,
    platformLogo,
  } = props;

  const {
    blog_link_text: { text: blogLinkText },
    invest_link_text: { text: investLinkText },
    au_retail_link_text: { text: auRetailLinkText },
    au_retail_page: { uid: auRetailUid },
    // nz_retail_link_text: { text: nzRetailLinkText },
    // nz_retail_page: { uid: nzRetailUid },
    ipo_link_text: { text: ipoLinkText },
    ipo_page: { uid: ipoUid },
    wholesale_link_text: { text: wholesaleLinkText },
    wholesale_page: { uid: wholesaleUid },
    raise_process_link_text: { text: raiseProcessLinkText },
    raise_page: { uid: raiseUid },
    team_link_text: { text: teamLinkText },
    social_links: socialLinks,
  } = settings?.data;

  const seoData = pageData && setupSeoData(pageData);

  const getGetRoutes = country => ({
    about: { to: `/about-us`, text: 'About Equitise' },
    team: { to: `/${prismicTeam?.uid}`, text: teamLinkText },
    contact: { to: `/contact`, text: 'Contact Us' },
    invest: { to: '/invest', text: investLinkText },
    retail: {
      to: `/${auRetailUid}`,
      text: auRetailLinkText,
    },
    // retail: {
    //   to: `/${country === AUSTRALIA ? auRetailUid : nzRetailUid}`,
    //   text: `${country === AUSTRALIA ? auRetailLinkText : nzRetailLinkText}`,
    // },
    ipo: { to: `/${ipoUid}`, text: ipoLinkText },
    wholesale: { to: `/${wholesaleUid}`, text: wholesaleLinkText },
    blog: { to: `/${prismicBlogLandingPage?.uid}`, text: blogLinkText },
    faq: { to: '/faq', text: 'Crowdfunding FAQ' },
    whatIsEcf: { to: `/${prismicBlogLandingPage?.uid}/equity-crowdfunding-guide`, text: 'What is Crowdfunding' },
    ia: { to: `/investor-agreement`, text: 'Investor Agreement' },
    // disclosure: { to: `/disclosure-statement`, text: 'Disclosure Statement' },
    warning: { to: `/warning-statement`, text: 'Warning Statements' },
    directDebitPolicy: { to: `/direct-debit`, text: 'Direct Debit' },
    fairDealing: { to: `/fair-dealing-policy`, text: 'Fair Dealing Policy' },
    fsg: { to: `/financial-service-guide`, text: 'Financial Services Guide' },
    raiseProcess: { to: `/${raiseUid}`, text: raiseProcessLinkText },
    startRaise: {
      to:
        'https://docs.google.com/forms/d/e/1FAIpQLSeEbmurSaLrgSoRq6z4ixPcWiVDwcFzRChrt59x4us8aSlfDw/viewform?usp=sf_link',
      text: 'Start Raise',
    },
    socialLinks,
    blogArchive: { to: `/blog-archive-timeline`, text: 'Historical Blogs' },
  });

  const defaultRoutes = getGetRoutes(AUSTRALIA);

  const [routes, setRoutes] = useState(defaultRoutes);

  useEffect(() => {
    const newRoutes = getGetRoutes(countryOfResidence);
    setRoutes(newRoutes);
  }, [countryOfResidence]);

  if (isIE) return <BadBrowser />;

  const validClassnames = inheritedClassnames || {};

  const headerClassnames = {
    header: validClassnames.header || '',
    headerBody: validClassnames.headerBody || '',
    wrapper: validClassnames.wrapper || '',
  };

  return (
    <Sentry>
      <main id="layout" className={bodyClassName || ''}>
        {isIE && <BadBrowser />}
        {!hideCommonUi && (
          <Header
            inheritedClassnames={headerClassnames}
            {...headerProps}
            routes={routes}
            location={location}
            platformLogo={platformLogo}
          />
        )}
        {pageData ? <SEO {...seoData} pathname={location?.pathname} article={article} /> : <SEO />}
        {children}
      </main>
      {!hideCommonUi && !isNewsletterCta && (
        <Footer routes={routes} inheritedClassname={validClassnames.footer || ''} />
      )}
    </Sentry>
  );
};

const settingsQuery = graphql`
  query {
    settings: prismicSettings {
      _previewable
      data {
        social_links {
          platform
          url {
            url
          }
        }
        blog_link_text {
          text
        }
        invest_link_text {
          text
        }
        au_retail_link_text {
          text
        }
        au_retail_page {
          uid
        }
        ipo_link_text {
          text
        }
        ipo_page {
          uid
        }
        wholesale_link_text {
          text
        }
        wholesale_page {
          uid
        }
        raise_page {
          uid
        }
        raise_process_link_text {
          text
        }
        team_link_text {
          text
        }
      }
    }
    prismicTeam {
      uid
    }
    prismicBlogLandingPage {
      uid
    }
  }
`;
